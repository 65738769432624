import React, { useEffect } from "react";

const Header = () => {

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: false
            },
            "googleTranslateElement"
        );
    };
    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    return (
        <header>
            <nav>
                <div className="logo-wrapper">
                    <img alt="" src="/images/header-logo.png" />
                </div>
                <div id="googleTranslateElement"></div>
            </nav>
        </header>
    )
}

export default Header