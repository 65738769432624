/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState, Suspense } from "react";
import Webcam from "react-webcam";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import * as cam from "@mediapipe/camera_utils";
import html2canvas from "html2canvas";
import Header from "./Header";
import Footer from "./Footer";
import './App.css';
import emailjs from "emailjs-com";

const App = () => {

  //Disable Right Click on Browser
  document.oncontextmenu = document.body.oncontextmenu = function () { return false; }

  // Refs for webcam and canvas elements
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // State variables
  const [imageURL, setimageURL] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [load, setLoad] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);
  const [snapshotURL, setSnapshotURL] = useState("");
  const [userEmail, setUserEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [backgrounds, setBackgrounds] = useState([]);
  const [appConfig, setAppConfig] = useState({});
  const [textContent, setTextContent] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState(new Image());

  // Load text content from script tag JSON
  useEffect(() => {
    const scriptTag = document.getElementById('text-content');
    if (scriptTag) {
      try {
        const content = JSON.parse(scriptTag.textContent);
        setTextContent(content);
      } catch (error) {
        console.error('Error parsing JSON from script tag:', error);
      }
    }

    // set the app config
    setAppConfig(getAppConfig());

  }, []);

  const getAppConfig = () => {
    const appConfig = document.getElementById('app-config');
    if (appConfig) {
      try {
        return JSON.parse(appConfig.textContent);
      } catch (error) {
        console.error('Error parsing App config:', error);
      }
    }
    return null;
  };

  // Preload background images
  const preloadImages = (images) => {
    images.forEach(image => {
      const img = new Image();
      img.src = image.src;
    });
  };

  // Fetch backgrounds from JSON file and preload them
  useEffect(() => {
    fetch('/backgrounds/index.php')
      .then(response => response.json())
      .then(data => {
        setBackgrounds(data);
        preloadImages(data); // Preload the images once we have the data
      })
      .catch(error => console.error('Error fetching background images:', error));
  }, []);

  // Display the Segmented video on top of background
  const onResults = async (results) => {
    const img = document.getElementById('vbackground');
    if (!img) return;

    /* eslint-disable no-unused-vars */
    const imgWidth = img.width;
    const imgHeight = img.height;
    /* eslint-enable no-unused-vars */

    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext('2d');
    canvasCtx.save();

    // Calculate the cropping percentages based on viewport width
    const viewportWidth = window.innerWidth;
    let leftCropPercentage = 0.025; // 2.5%
    let rightCropPercentage = 0.025; // 2.5%
    let topCropPercentage = 0.04; // 4%
    let bottomCropPercentage = 0.04; // 4%

    if (viewportWidth <= 800) {
      leftCropPercentage = 0.03; // 3%
      rightCropPercentage = 0.03; // 3%
      topCropPercentage = 0.018; // 1.8%
      bottomCropPercentage = 0.018; // 1.8%
    }

    // Calculate the position to center the segmented image on the canvas
    const cropWidth = results.image.width * (1 - leftCropPercentage - rightCropPercentage);
    const cropHeight = results.image.height * (1 - topCropPercentage - bottomCropPercentage);
    const cropX = (results.image.width - cropWidth) / 2;
    const cropY = (results.image.height - cropHeight) / 2;
    const centerX = (canvasElement.width - cropWidth) / 2;
    const centerY = (canvasElement.height - cropHeight) / 2;

    // Clear the canvas
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Draw the segmented image centered on the canvas with cropping
    canvasCtx.drawImage(
      results.image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      centerX,
      centerY,
      cropWidth,
      cropHeight
    );

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = 'destination-atop';
    canvasCtx.drawImage(
      results.segmentationMask,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      centerX,
      centerY,
      cropWidth,
      cropHeight
    );

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = 'destination-over';
    canvasCtx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.restore();
    setLoad(true);
  };

  // Perform Segmentation on live webcam
  useEffect(() => {
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
      },
    });
    selfieSegmentation.setOptions({
      modelSelection: 1,
    });
    selfieSegmentation.onResults(onResults);

    let camera;

    if (webcamVisible && webcamRef.current) {
      camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          await selfieSegmentation.send({ image: webcamRef.current.video });
        },
      });
      camera.start();
    }

    return () => {
      if (camera) {
        camera.stop();
      }
      selfieSegmentation.close();
    };
  }, [webcamVisible]);

  // Update background image when imageURL changes
  useEffect(() => {
    const img = new Image();
    img.src = imageURL;
    img.onload = () => {
      setImage(img);
    };
  }, [imageURL]);

  // Functions for opening and closing modals
  function openInstructionsModal() {
    let instructionsModal = document.getElementById('instructionsModal');
    instructionsModal.style.display = "flex";
  }

  function openBackgroundsModal() {
    let backgroundsModal = document.getElementById('backgroundsModalWrapper');
    backgroundsModal.style.display = "flex";
    setWebcamVisible(true);
  }

  const openOutputModal = async () => {
    let outputModal = document.getElementById('outputModalWrapper');
    outputModal.style.display = "block";
  }

  function closeInstructionsModal() {
    let instructionsModal = document.getElementById('instructionsModal');
    instructionsModal.style.display = "none";
  }

  function closeWebcamModal() {
    setWebcamVisible(false);
    let webcamModal = document.getElementById('webcamModalWrapper');
    webcamModal.style.display = "none";
    console.log("closeWebcamModal is called");
  }

  function closeOutputModal() {
    let outputModal = document.getElementById('outputModalWrapper');
    outputModal.style.display = "none";
  }

  function closeBackgroundsModal() {
    let backgroundsModal = document.getElementById('backgroundsModalWrapper');
    backgroundsModal.style.display = "none";
  }

  const captureSnapshot = async () => {
    try {
      const canvas = await html2canvas(document.getElementById("video"));
      const compressedDataURL = await compressImage(canvas.toDataURL(), 1500);
      setSnapshotURL(compressedDataURL);
      openOutputModal();
    } catch (error) {
      console.error("Error capturing and compressing snapshot:", error);
    }
  };

  // Function to compress the image
  const compressImage = async (dataURL, maxSizeInKB) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = dataURL;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const maxDimension = Math.sqrt(maxSizeInKB * 1024);
        let width = image.width;
        let height = image.height;

        if (width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        }, "image/jpeg", 1); // Adjust quality as needed
      };
    });
  };

  // Function to send the email
  function sendCanvasAsAttachment() {
    if (isValidEmail) {
      const base64 = snapshotURL;

      if (appConfig.emailjs) {
        // Use the config values to send an email
        emailjs.send(
          appConfig.emailjs.service_id,
          appConfig.emailjs.template_id,
          {
            content: base64,
            mail_to: userEmail
          },
          appConfig.emailjs.user_id
        )
          .then(() => {
            alert('Image was Successfully sent!');
            window.location.replace("");
          })
          .catch((error) => {
            console.error("Error sending email:", error);
            alert("Error sending email. Please try again later.");
          });
      } else {
        alert("EmailJS configuration is not available.");
      }
    } else {
      alert("Please enter a valid email address.");
    }
  }

  // Function to validate email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  // Handle email input change
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setUserEmail(email);
    setIsValidEmail(validateEmail(email));
  }

  return (
    <>
      <Header />
      <main>
        {appConfig.hero_type === "video" ? (
          <>
            <video className="heroVideo" src={appConfig.hero_video_landscape} autoPlay muted loop></video>
            <video className="heroVideoPhone" src={appConfig.hero_video_portrait} autoPlay muted loop></video>
          </>
        ) : (
          <>
            <img className="heroImage landscape" src={appConfig.hero_static_image_landscape} alt="Hero Landscape" />
            <img className="heroImage portrait" src={appConfig.hero_static_image_portrait} alt="Hero Portrait" />
          </>
        )}

        <div className="overlay-container">
          <div className="overlay-wrapper">
            <p>{textContent.overlayDescription}</p>
            <button onClick={openInstructionsModal}>{textContent.startButtonText}</button>
          </div>
        </div>
        <div className="modal-wrapper" id="instructionsModal">
          {/* Close button */}
          <a
            onClick={closeInstructionsModal}>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c9.4-9.4 9.4-24.6 0-33.9z" /></svg></a>
          <div className="instructionsModalDiv1">
            <p>{textContent.instructionStep1}</p>
            <p>{textContent.instructionStep2}</p>
            <p>{textContent.instructionStep3}</p>
            <p>{textContent.instructionStep4}</p>
          </div>
          <div className="instructionsModalDiv2">
            <img src="../assets/Start.webp" onClick={openBackgroundsModal} alt="Start" />
            <p>{textContent.startLabel}</p>
          </div>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          {webcamVisible && (
            <div className="modal-wrapper" id="webcamModalWrapper">
              {/* Close button */}
              <a onClick={closeWebcamModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c9.4-9.4 9.4-24.6 0-33.9z" /></svg></a>
              <div className="input-wrapper">
                <div className="videoContainer">
                  <div className="videoContent">
                    <div className="video" id="video" >
                      <Webcam
                        ref={webcamRef}
                        style={{
                          display: "none",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      ></canvas>
                    </div>
                  </div>
                  <button onClick={captureSnapshot}>{textContent.captureButtonText}</button>
                </div>
                <div className="backgroundContainer">
                  <div className="backgrounds">
                    <img id="vbackground" src={imageURL} alt="The Screen" className="background" />
                  </div>
                  <button onClick={openBackgroundsModal}>{textContent.chooseBackgroundButtonText}</button>
                </div>
              </div>
            </div>
          )}
        </Suspense>
        <div className="modal-wrapper" id="backgroundsModalWrapper">
          {/* Close button */}
          <a onClick={closeBackgroundsModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c9.4-9.4 9.4-24.6 0-33.9z" /></svg></a>
          {backgrounds.map((background, index) => (
            <img
              key={index}
              src={background.src}
              className={background.class}
              alt={background.alt}
              loading="lazy"
              onClick={() => {
                setimageURL(background.src);
                closeBackgroundsModal();
              }}
            />
          ))}
        </div>
        <div className="modal-wrapper" id="outputModalWrapper">
          <div className="output-wrapper" id="output">
            <img src={snapshotURL} alt="Snapshot" />
            <input
              type="email"
              id="emailInput"
              className="inputDisplay"
              placeholder={textContent.emailPlaceholder}
              required
              value={userEmail}
              onChange={handleEmailChange}
            />
            <div className="output-btns">
              <input id="download"
                type="submit"
                className="inputDisplay"
                onClick={sendCanvasAsAttachment}
                value={textContent.downloadButtonText}
              />
              <button onClick={closeOutputModal}>{textContent.retakeSnapshotButtonText}</button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
export default App;
